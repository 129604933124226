@pcolor: #F0C54F;

.file-page {
  .file-page-upload-panel-container {
    background-color: white;
    overflow: hidden;
    position: fixed;
    left: 88px;
    transition: all 0.5s;
    z-index: 1001;

    .ant-upload, .ant-upload-drag {
      border: none;
      border-radius: 0;
    }

    .upload-init-panel {
      // width: 360px;
      // height: 80px;
      // border-radius: 40px;
      font-size: 25px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: bold;
    }

    .upload-filelist-panel {
      width: 310px;
      height: 452px;
      border-radius: 12px;
      position: relative;

      .ant-upload, .ant-upload-drag {
        border: none;
        border-radius: 0;
        height: auto;
        text-align: initial;

        > .ant-upload, .ant-upload-btn {
          padding: 0;
        }
      }

      .upload-panel-header {
        height: 44px;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 20px 5px 20px;
        background-color: rgb(247, 247, 247);

        .upload-panel-upload-inner-container {
          width: 32px;
          height: 32px;
          background-color: rgb(240, 197, 79);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }

      .upload-panel-file-list {
        height: 132px;
        background-color: rgb(247, 247, 247);
        padding: 0 20px;
        overflow-y: auto;
    
        .filelist-container {
          height: 44px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          .filelist-remove-icon {
            display: none;
          }
        }
    
        .filelist-container > div:nth-child(1) {
          display: flex;
          align-items: center;
        }

        .filelist-container:hover .filelist-remove-icon {
          display: block;
        }
      }

      .upload-panel-transfer-rules {
        padding: 0 20px;
      }

      .upload-panel-transfer-rules > div:not(:nth-child(5)) {
        height: 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .upload-panel-transfer-rules > div:not(:nth-child(5)) > div:first-child {
        font-size: 13px;
        font-weight: bold;
      }

      .upload-panel-transfer-rules > div:not(:nth-child(5)) > div:last-child {
        font-size: 14px;
      }

      .upload-panel-transfer-rules > div:nth-child(5) {
        display: flex;
        justify-content: space-between;
        margin: 16px 0 18px 0;
      }

      .upload-panel-transfer-rules > div:nth-child(5) > div {
        font-size: 11px;
        color: rgb(161, 161, 161);
      }
    }

    .upload-filelist-panel > span > .ant-upload {
      width: 100%;
    }
  }

  .move-forward:hover {
    transform: translateX(-20px);
  }

  .file-page-desc {
    width: 360px;
    // height: 120px;
    padding: 24px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0,0.75);
    color: rgb(240, 197, 79);
    font-size: 16px;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 88px;
    top: calc(50% + 64px);
  }

  .file-page-bar {
    height: 40px;
    background-color: white;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bolder;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.13);
    position: fixed;
    right: 24px;
    top: 24px;
    z-index: 1001;

    .user-container {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: rgb(255, 195, 0);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.file-page-transfer-history-drawer {
  .ant-drawer-content-wrapper {
    .transfer-history-table-container {
      .ant-table-wrapper {
        .ant-table {
          .ant-table-container {
            .ant-table-header {
              table {
                thead {
                  tr {
                    th {
                      background-color: transparent;
                      border-bottom: none;
                      color: #A1A1A1;
                      font-weight: 400;
                    }

                    th::before {
                      display: none;
                    }
                  }
                }
              }
            }

            .ant-table-body {
              table {
                tbody {
                  .ant-table-row:hover {
                    .ant-table-cell-row-hover {
                      background-color: @pcolor;
                    }
                  }

                  .ant-table-expanded-row {
                    td {
                      background-color: transparent;
                    }
                  }

                  .ant-table-expanded-row:hover {
                    .ant-table-cell-row-hover {
                      background-color: #fafafa;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: rgb(240, 197, 79);