.custom-input-container {
  width: 280px;
  height: 48px;
  position: relative;
}

.custom-input-container input {
  width: 100%;
  padding: 17px 20px;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  color: rgb(37, 37, 37);
  transition: 0.1s;
}

.custom-input-container span {
  position: absolute;
  left: 0;
  padding: 17px 24px;
  pointer-events: none;
  font-size: 16px;
  color: rgb(161, 161, 161);
  transition: 0.5s;
}

.custom-input-container input:valid ~ span,
.custom-input-container input:focus ~ span {
  color: rgb(240, 197, 79);
  transform: translateX(25px) translateY(-11px);
  background: white;
  font-size: 14px;
  padding: 0 5px;
}

.custom-input-container input:valid,
.custom-input-container input:focus {
  border: 2px solid rgb(240, 197, 79);
}

@primary-color: rgb(240, 197, 79);