html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  transition: background-image 3.5s ease-in-out;
}

#root {
  width: 100%;
  height: 100%;
}
