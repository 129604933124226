.custom-prefixinput-container {
  width: 280px;
  height: 48px;
  position: relative;
}

.custom-prefixinput-container > .custom-prefixinput-select {
  position: absolute;
  left: 0;
  top: 15.5px;
}

.custom-prefixinput-container > .custom-prefixinput-select > .ant-select-arrow {
  right: 17px;
}

.custom-prefixinput-container > input {
  width: 100%;
  padding: 17px 8px 17px 58px;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  color: rgb(37, 37, 37);
  transition: 0.1s;
}

.custom-prefixinput-container > span {
  position: absolute;
  left: 0;
  top: 0;
  // padding: 17px 68px;
  padding-top: 18px;
  padding-left: 64px;
  pointer-events: none;
  font-size: 16px;
  color: rgb(161, 161, 161);
  transition: 0.5s;
}

.custom-prefixinput-container > input:valid ~ span,
.custom-prefixinput-container > input:focus ~ span {
  color: rgb(240, 197, 79);
  transform: translateX(25px) translateY(-11px);
  background: white;
  font-size: 14px;
  padding: 0 5px;
}

.custom-prefixinput-container > input:valid,
.custom-prefixinput-container > input:focus {
  border: 2px solid rgb(240, 197, 79);
}

@primary-color: rgb(240, 197, 79);