@font-face {
  font-family: 'Squada One';
  src: url(../../media/font/Squada-One.ttf);
}

.app-title {
  font-size: 32px;
  font-weight: 400;
  color: white;
  position: fixed;
  top: 16px;
  left: 40px;
  font-family: 'Squada One';
}

.info {
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: flex;
  color: white;
  font-size: 12;
}

.info > div:nth-child(2n) {
  margin: 0 6px;
}

.info > div:nth-child(2n+1) {
  letter-spacing: 1px;
}

@primary-color: rgb(240, 197, 79);