.uploading-chart {
  width: 192px;
  height: 192px;
  background: #eaeaea;
  border-radius: 50%;
  position: relative;
  transition: 0.5s;
  border: 0.5px solid #ffc831;
  padding: 4px;
  transform: translateY(-15px) scale(0.7, 0.7);
}

.uploading-chart .process-anime {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 4px solid #fff;
  transform: translate(-50%, -50%);
  width: 190px;
  height: 190px;
  background: linear-gradient(45deg, #ffc831, #fdeca6);
  border-radius: 50%;
  overflow: hidden;
}

.uploading-chart .process-anime .cube-a {
  background: rgba(53, 53, 53, 0.30196078431372547);
  animation: fx-rotate 15s linear infinite;
}

.uploading-chart .process-anime .cube-b {
  background: #fff;
  animation: fx-rotate 10s linear infinite reverse;
}

.uploading-chart .process-anime .cube {
  position: absolute;
  left: 50%;
  border-radius: 50%;
  width: 192px;
  height: 170px;
  transform-origin: 50% 50%;
  transition: 0.5s;
}

.uploading-chart .fx-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(137deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0));
  top: 0;
  left: 0;
  z-index: 1;
}

.uploading-chart .fx {
  position: absolute;
  z-index: 2;
  top: 20%;
  left: 10%;
  opacity: 0.5;
}

img {
  display: block;
  border-style: none;
}

#value {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
}

.tick {
  animation: tick 1.8s ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.93s;
  animation-delay: 0s;
  stroke-dasharray: 350;
  stroke-dashoffset: 0;
}

@keyframes tick {
  from {
      stroke-dashoffset: 300;
  }

  to {
      stroke-dashoffset: 0;
  }
}

@keyframes fx-rotate {
  0% {
      transform: translate(-50%) rotate(0);
  }

  100% {
      transform: translate(-50%, 0px) rotate(365deg);
  }
}
