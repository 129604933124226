.policy-page-add-file {
  width: 360px;
  height: 80px;
  border-radius: 40px;
  font-size: 25px;
  font-weight: bold;
  background-color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: fixed;
  top: calc(50% - 40px);
  left: 88px;
}

.policy-page-desc {
  width: 360px;
  // height: 120px;
  padding: 24px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.75);
  color: rgb(240, 197, 79);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 88px;
  top: calc(50% + 64px);
}

.policy-page-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-header-title {
      justify-content: flex-end;
    }
  }
}

@primary-color: rgb(240, 197, 79);