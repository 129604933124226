.login-drawer {
  .login-drawer-form-container {
    width: 400px;
    // height: 426px;
    border: 1px solid rgb(225, 225, 225);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 60px 20px;

    .login-drawer-form-note {
      font-size: 12px;
      color: rgb(161, 161, 161);
      text-align: center;
    }
  }

  .login-drawer-form-container > div:nth-child(1) {
    font-size: 40px;
    font-weight: bold;
    margin-top: 48px;
  }

  .login-drawer-form-container > div:nth-child(2) {
    font-size: 12px;
    color: rgb(161, 161, 161);
    margin-bottom: 32px;
  }
}
@primary-color: rgb(240, 197, 79);