@import '~antd/dist/antd.less';

.App {
  width: 100%;
  height: 100%;

  
  .hvr-backward {
    // display: inline-block;
    // vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }
  .hvr-backward:hover, .hvr-backward:focus, .hvr-backward:active {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

@primary-color: rgb(240, 197, 79);