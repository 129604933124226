.login-page {
  width: 100%;
  height: 100%;

  .login-page-add-file {
    width: 360px;
    height: 80px;
    border-radius: 40px;
    font-size: 25px;
    font-weight: bold;
    background-color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: fixed;
    top: calc(50% - 40px);
    left: 88px;
    // z-index: 1001;
  }

  .login-page-desc {
    width: 360px;
    // height: 120px;
    padding: 24px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.75);
    color: rgb(240, 197, 79);
    font-size: 16px;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 88px;
    top: calc(50% + 64px);
  }

  .login-page-register-or-login {
    height: 40px;
    padding: 0 18px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bolder;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    position: fixed;
    right: 24px;
    top: 24px;
    z-index: 1001;
  }
}
@primary-color: rgb(240, 197, 79);